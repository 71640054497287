import Box from '@mui/material/Box';
import Footer from '@/components/Footer';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

const Home = () => {
  const isMobile = useSelector((state) => state.wallet.isMobile);
  const { t } = useTranslation('common');
  const router = useRouter();
  const Card = ({ title, image, children, isRoute = true, url }) => (
    <Box
      sx={{
        width: isMobile ? '80%' : '285px ',
        background: '#FFFFFF',
        padding: '31px 20px 43px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#4C4948',
        boxShadow: '0px 8px 8px #0000001A',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: '22px',
            letterSpacing: '1px',
            marginBottom: '34px',
          }}
        >
          {title}
        </Box>
        <Box
          component="img"
          sx={{
            width: '74px',
            height: '74px',
          }}
          src={`/static/${image}.svg`}
          alt={image}
        ></Box>
        <Box
          sx={{
            fontSize: '15px',
            lineHeight: '28px',
            margin: '34px 0 53px 0',
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        onClick={() => {
          if (isRoute) {
            router.push({
              pathname: '/mint',
              query: { id: url },
            });
          } else {
            window.open(url, '_blank');
          }
        }}
        sx={{
          background: '#F3F3F3',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
          width: '120px',
          height: '26px',
          cursor: 'pointer',
          border: '1px solid #333333',
          borderRadius: '20px',
        }}
      >
        {t('home.btn')} >
      </Box>
    </Box>
  );
  return (
    <Box
      sx={{
        width: '100%',
        background: '#F2F7FA',
      }}
    >
      {isMobile ? (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              width: 'auto',
              height: '300px',
              background: 'linear-gradient(to top right, #0096fc, #00d4fd)',
            }}
          >
            <Box
              sx={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontSize: '30px',
                color: '#fff',
              }}
            >
              <Box
                sx={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  fontSize: '28px',
                  color: '#fff',
                }}
              >
                <Box sx={{ marginBottom: '15px', marginTop: '40px' }}>
                  KEYVOX NFT
                </Box>
                <Box sx={{ fontSize: '18px' }}>{t('home.banner')}</Box>
              </Box>
              <Box
                component="img"
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: 'auto',
                  bottom: '0px',
                }}
                src="/static/indexsqure.png"
                alt="home_banner"
              ></Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              width: 'auto',
              height: '300px',
              background: 'linear-gradient(to top right, #0096fc, #00d4fd)',
            }}
          ></Box>

          <Box
            sx={{
              position: 'absolute',
              top: '0',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                maxWidth: '1440px',
                height: '100%',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '400px',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: '28px',
                  color: '#fff',
                }}
              >
                <Box sx={{ marginBottom: '15px' }}>KEYVOX NFT</Box>
                <Box sx={{ fontSize: '18px' }}>{t('home.banner')}</Box>
              </Box>
              <Box
                component="img"
                sx={{
                  width: 'auto',
                  height: '100%',
                }}
                src="/static/indexsqure.png"
                alt="home_banner"
              ></Box>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '51px 0 10px 0',
        }}
      >
        <Box
          sx={
            isMobile
              ? {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '28px',
                  color: '#4C4948',
                }
              : {
                  fontSize: '30px',
                  letterSpacing: '0.6px',
                  color: '#4C4948',
                }
          }
        >
          {t('home.title')}
        </Box>
        <Box
          sx={{
            width: '54px',
            height: '4px',
            background: '#333333',
            margin: '10px 0 45px 0',
          }}
        />
        <Box
          sx={
            isMobile
              ? {
                  fontSize: '18px',
                  color: '#4C4948',
                  marginBottom: '45px',
                  padding: '0 20px 0 20px',
                }
              : {
                  maxWidth: '551px',
                  fontSize: '18px',
                  color: '#4C4948',
                  marginBottom: '45px',
                }
          }
        >
          {t('home.subtitle')}
        </Box>
        <Box
          sx={
            isMobile
              ? {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '31px',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              : {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '31px',
                }
          }
        >
          <Card
            title={t('home.NFT.title')}
            image="keyvox-nft"
            isRoute={false}
            url="https://www.keyvox.co/nft"
          >
            {t('home.NFT.description')}
          </Card>
          <Box sx={{ width: '15px', height: '15px' }} />
          <Card
            title={t('home.overview.title')}
            image="distribution-of-sales"
            url="overview"
          >
            {t('home.overview.description')}
          </Card>
          <Box sx={{ width: '15px', height: '15px' }} />
          <Card title={t('home.roadmap.title')} image="roadmap" url="roadmap">
            {t('home.roadmap.description')}
          </Card>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale ?? 'jp', ['common'])),
  },
});

export default Home;
